<template>
  <q-layout>
    <q-header>
      <q-toolbar>
        <q-btn dense round @click="leftDrawerOpen = !leftDrawerOpen">
          <q-avatar>
            <img src="@/assets/DEPools_logo2_128x128.png" />
          </q-avatar>
        </q-btn>
        <q-toolbar-title style="font-size: 1em;">
          Deep End Pools LLC
        </q-toolbar-title>
      </q-toolbar>
    </q-header>
    <q-drawer v-model="leftDrawerOpen" side="left" overlay behavior="desktop">
      <q-list>
        <q-item clickable v-ripple :to="{ name: 'HomePage', hash: '#about' }">
          <q-item-section>About</q-item-section>
        </q-item>
        <q-item clickable v-ripple :to="{ name: 'HomePage', hash: '#services' }">
          <q-item-section>Services</q-item-section>
        </q-item>
        <q-item clickable v-ripple :to="{ name: 'HomePage', hash: '#gallery' }">
          <q-item-section>Gallery</q-item-section>
        </q-item>
        <q-item clickable v-ripple :to="{ name: 'HomePage', hash: '#contact' }">
          <q-item-section>Contact Us</q-item-section>
        </q-item>
        <!-- ... other items -->
      </q-list>
    </q-drawer>
    <div id="app">
      <router-view />
    </div>
  </q-layout>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      leftDrawerOpen: false
    }
  }
}
</script>

<style src="./assets/main.css"></style>
