<template>
  <q-page-container style="padding-top: 0;">
    <!-- Page Content -->
    <q-banner style="margin: 0; padding: 0;" class="bg-grey-1">
      <img src="@/assets/DEPools_Header.png" style="width: 100%; height: 60em;">
    </q-banner>
    <q-page padding>
      <section class="home-page-section" id="about" ref="about">
        <h1>About Us</h1>
        <!-- ... content -->
        <p>Deep End Pools LLC is a leading provider of residential and commercial swimming pool services.</p>
      </section>
      <section class="home-page-section" id="services" ref="services" style="text-align: right;">
        <h1>Our Services</h1>
        <ServiceList />
      </section>
      <section class="home-page-section" id="gallery" ref="gallery" style="text-align: left;">
        <h1>Gallery</h1>
        <GallerySection />
      </section>
      <section style="text-align: center;" id="contact" ref="contact">
        <h1>Contact Us</h1>
        <!-- ... content -->
        <p>Email: ContactUs@DeepEndPools.com</p>
        <p>Primary (Nathan Finan): 978-868-8479</p>
        <p>Secondary (Joseph Denio): 978-875-3178</p>
      </section>
      <!-- ... other sections -->
    </q-page>
  </q-page-container>
</template>

<script>
import ServiceList from './ServiceList.vue';
import GallerySection from './GallerySection.vue';

export default {
  name: 'HomePage',
  components: {
    ServiceList,
    GallerySection
  }
}
</script>