import axios from 'axios';

const apiClient = axios.create({
    baseURL: `http://localhost:5000/api`,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
});

export default {
    getPoolServices() {
        //return apiClient.get('/poolservice');
        return new Promise((resolve) => {
            // Simulating HTTP request
            resolve({
                data: [
                    { id: 1, name: 'Pool Cleaning', description: 'Our pool cleaning services are designed to keep your pool sparkling clean and safe for use...', imageUrl: ''},
                    { id: 2, name: 'Pool Maintenance', description: 'Regular maintenance is crucial for the longevity of your pool...', imageUrl: `${require("../assets/gallery/inground-round-pool1.png")}` },
                    { id: 3, name: 'Pool Renovation', description: 'Looking to give your pool a facelift?...', imageUrl: '' }
                ]
            });
        });
    },
    getPoolService(id) {
        return apiClient.get(`/poolservice/${id}`);
    },
    addPoolService(service) {
        return apiClient.post('/poolservice', service);
    },
    updatePoolService(service) {
        return apiClient.put(`/poolservice/${service.id}`, service);
    },
    deletePoolService(id) {
        return apiClient.delete(`/poolservice/${id}`);
    }
};