import Vuex from 'vuex';
import PoolServiceApi from '@/services/PoolServiceApi';

export default new Vuex.Store({
  state: {
    services: []
  },
  mutations: {
    SET_SERVICES(state, services) {
      state.services = services;
    }
  },
  actions: {
    fetchServices({ commit }) {
      PoolServiceApi.getPoolServices()
        .then(response => {
          commit('SET_SERVICES', response.data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  getters: {
    allServices: state => state.services
  }
});
