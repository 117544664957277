<template>
    <div class="gallery-section">
        <div class="gallery">
            <div class="gallery-carousel">
                <div class="gallery-items" ref="galleryItems">
                    <div v-for="image in images" :key="image.id" class="gallery-item">
                        <img :src="image.url" :alt="image.alt" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GallerySection',
    data() {
        return {
            images: [
                {
                    id: 1,
                    url: require("../assets/gallery/long-inground-pool1.png"),
                    alt: "Pool Image 1",
                },
                {
                    id: 2,
                    url: require("../assets/gallery/long-inground-pool2.png"),
                    alt: "Pool Image 2",
                },
                {
                    id: 3,
                    url: require("../assets/gallery/inground-round-pool1.png"),
                    alt: "Pool Image 3",
                },
                {
                    id: 4,
                    url: require("../assets/gallery/indoor-pool1.png"),
                    alt: "Pool Image 4",
                }
                // Add more images as needed
            ],
        };
    },
    mounted() {
        this.initCarousel();
    },
    methods: {
        initCarousel() {
            const galleryItems = this.$refs.galleryItems;
            const galleryWidth = galleryItems.offsetWidth;
            const galleryItemsWidth = galleryItems.scrollWidth;

            if (galleryItemsWidth > galleryWidth) {
                let scrollPosition = 0;
                const scrollAmount = 300;

                setInterval(() => {
                    if (scrollPosition + galleryWidth < galleryItemsWidth) {
                        scrollPosition += scrollAmount;
                    } else {
                        scrollPosition = 0;
                    }
                    galleryItems.scrollTo({
                        left: scrollPosition,
                        behavior: "smooth",
                    });
                }, 3000);
            }
        },
    },
}
</script>

<style scoped>
.gallery-section {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.gallery {
    width: 100%;
    overflow: hidden;
}

.gallery-carousel {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
}

.gallery-items {
    display: flex;
    scroll-snap-align: start;
}

.gallery-item {
    flex: 0 0 auto;
    width: 320px;
    margin-right: 10px;
    overflow: hidden;
    position: relative;
}

.gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.gallery-item:hover img {
    transform: scale(1.1);
}
</style>