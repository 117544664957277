import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
// import routerRoutes from './router';
import store from './store';
import 'quasar/dist/quasar.css'
import { Quasar } from 'quasar'
import HomePage from '@/components/HomePage.vue';

function smoothScroll(target, duration) {
  var targetElement = document.querySelector(target);
  var targetPosition = window.pageYOffset + targetElement.getBoundingClientRect().top;
  var startPosition = window.pageYOffset;
  var distance = targetPosition - startPosition;
  var startTime = null;

  function animation(currentTime) {
    if (startTime === null) startTime = currentTime;
    var timeElapsed = currentTime - startTime;
    var run = ease(timeElapsed, startPosition, distance, duration);
    window.scrollTo(0, run);
    if (timeElapsed < duration) requestAnimationFrame(animation);
  }

  function ease(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  }

  requestAnimationFrame(animation);
}
// Usage:
// smoothScroll('#target-element', 2000);

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'HomePage',
      component: HomePage
    },
    // Define more routes for other components
  ],
  async scrollBehavior(to) {
    if (to.hash) {
      await new Promise((resolve) => {
        smoothScroll(to.hash, 1000, resolve);
      });
      return { el: to.hash }
    } else {
      return { left: 0, top: 0 }
    }
  }
});

createApp(App)
  .use(Quasar)
  .use(router)
  .use(store)
  .mount('#app')
