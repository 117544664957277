<template>
  <div>
    <ul>
      <li class="home-page-section" v-for="service in services" :key="service.id">
        <div style="display: flexbox" class="service-item">
          <img style="float: left; width: 200px;" :src="service.imageUrl" alt="Service Image" class="service-image">
          <div class="service-details">
            <h2>{{ service.name }}</h2>
            <table class="right-aligned-table" style="text-align: left;">
              <tr style="color: green; font-size: larger;">
                ${{ service.price }}
              </tr>
              <tr style="font-size: larger;">
                {{ service.description }}
              </tr>
            </table>
          </div>
        </div>
        <!-- More interactive elements can be added here -->
      </li>
    </ul>
    <p v-if="error" class="error">{{ error }}</p>
  </div>
</template>
  
<script>
import PoolServiceApi from '@/services/PoolServiceApi';

export default {
  name: 'ServiceList',
  data() {
    return {
      services: [],
      error: null
    };
  },
  created() {
    this.fetchServices();
  },
  methods: {
    fetchServices() {
      PoolServiceApi.getPoolServices()
        .then(response => {
          this.services = response.data;
        })
        .catch(error => {
          this.error = "Failed to load services";
          console.error(error);
        });
    }
  }
};
</script>
  
<style src="../assets/main.css"></style>
